import React from 'react'
import Helmet from 'react-helmet'

import { Hero, Network, DeveloperTools, Benefits } from '../components/Chainpoint'
import Layout from '../components/layout'

const About = props => {
  const { tools, benefits } = props.data.contentJson.chainpoint

  return (
    <Layout location={props.location}>
      <div className='page-chainpoint'>
        <Helmet
          title={`Chainpoint`}
          meta={[
            {
              name: 'description',
              content: 'Chainpoint is an open standard for anchoring data to the blockchain to create a timestamp proof.'
            }
          ]}
        />

        <Hero
          linkHref='https://github.com/chainpoint/chainpoint-start'
          linkText='Chainpoint Github'
          title='For developers'
          subtitle='Chainpoint'
          text='Chainpoint is an open standard for linking data to the blockchain to create a timestamp proof.'
        />

        <Network
          title='Chainpoint network'
          subtitle='Chainpoint is a global network for anchoring data to the Bitcoin blockchain'
        />

        <DeveloperTools
          title='Developer Tools'
          subtitle='Get started in minutes'
          tools={tools}
        />

        <Benefits featureLists={benefits} />

      </div>
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  query ChainpointPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    contentJson {
      chainpoint {
        tools {
          icon
          linkText
          linkHref
          text
        }
        benefits {
          icon
          title
          text
          listItems {
            icon
            text
          }
        }
      }
    }
  }
`
