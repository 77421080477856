import React from 'react'

import { importAll } from '../utils/helpers'

import {
  Section,
  SectionTitle,
  ContainedRow,
  CtaHero
} from './UI'

const pageImgs = importAll(require.context('../img/chainpoint', false, /\.(png|jpe?g|svg)$/))

const Hero = props => (
  <Section {...props} className='hero'>
    <CtaHero
      {...props}
      containerClass='background-chainpoint'
      contentClass='col-md-7'
      buttonClass='btn-secondary'
    />
  </Section>
)

const Network = props => (
  <Section {...props} className='network'>
    <SectionTitle {...props} />
  </Section>
)

const DeveloperTools = props => (
  <Section {...props} className='developertools'>
    <SectionTitle {...props} />
    <ContainedRow className='justify-content-center developer-tools'>
      {props.tools.map((tool, index) =>
        <div className='col col-12 col-sm-8 col-lg-6 col-xl-6' key={index}>
          <a className='ui-card' href={tool.linkHref} target='_blank' rel='noopener noreferrer'>
            <img src={pageImgs[`./${tool.icon}`]} alt='Tool icon' />
            <div

              className='arrow-link'
              target='_blank'
            >
              {tool.linkText}
            </div>
            <div className='paragraph'>
              {tool.text}
            </div>
          </a>
        </div>
      )}
    </ContainedRow>
  </Section>
)

const Benefits = props => (
  <Section {...props} className='benefits'>
    <ContainedRow>
      {props.featureLists.map((featureList, index) =>
        <div className={`feature-list-outer-${index} col col-md-5`} key={index}>
          <div className='feature-list'>
            <div className='feature-list-inner'>
              <img src={pageImgs[`./${featureList.icon}`]} alt='Feature list icon' />
              <h2 className='feature-list-title'>{featureList.title}</h2>
              <p className='paragraph'>{featureList.text}</p>
              {featureList.listItems.map((item, index) =>
                <div className='feature-list-item' key={index}>
                  <img src={pageImgs[`./${item.icon}`]} alt='Item icon' />
                  {item.text}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </ContainedRow>
  </Section>
)

export {
  Hero,
  Network,
  DeveloperTools,
  Benefits
}
